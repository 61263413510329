import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import abductionIllustration from '../assets/svgs/abduction-illustration.svg';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title='404: Not found' />
      <div>
        <img
          alt='Ghost getting abducted by aliens'
          className='block mx-auto w-1/2'
          src={abductionIllustration}
        />
      </div>
    </Layout>
  );
}

export default NotFoundPage;
